<template>
  <div class="page page-404 bg-primary-900 min-h-footer w-full pb-20 text-white pt-8">
    <div class="adaptive-background">
      <img :src="background" />
    </div>
    <div class="flex flex-nowrap w-page my-0 mx-auto relative z-20 flex flex-col h-96">
      <div class="page-link">
        <h1 class="mx-auto text-center text-2xl">Désolé ... page introuvable</h1>
      </div>
      <img :src="logo404" class="logo-404"/>
      <div class="btn-link">
        <router-link :to="{ name: $routeNames.Home }"
                     class="mx-auto px-8 py-3 bg-primary-500 text-white transition hover:bg-white hover:text-primary-900 rounded-full">
          Retour à l'accueil
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import * as ImageHelper from '@/_helpers/image.helper';

export default {
  name: 'Error404',
  data() {
    return {
      background: ImageHelper.resolve('404Page-Fond.svg'),
      logo404: ImageHelper.resolve('404.svg'),
    };
  },
};
</script>

<style scoped>
.adaptive-background img {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
}
.btn-link {
  position: fixed;
  top: 70vh;
  left: 0;
  display: flex;
  width: 100vw;
}

.page-link {
  position: fixed;
  top: 20vh;
  left: 0;
  display: flex;
  width: 100vw;
}

.page-404 {
  position: relative;
}

.page-404::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  top: 0;
  left: 0;
}
</style>
